@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --first-color: #8a55fb;
  --first-color-60: #8a55fb99;
  --second-color: #0c0318;
  --title-color: #0b0c0d;
  --paragraph-color: #353a43;
  --neutral-gray: #98949e99;
  --semantic-red-100: #f01b0d;
}

body {
  background-color: white !important;
  font-size: 16px;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--second-color)
}

button {
  color: #0c0318;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0b0c0d;
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

@media only screen and (max-width: 370px) {
  h1 {
    font-size: 22px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 370px) and (max-width: 768px) {
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
}

h2 {
  font-size: 32px;
  font-weight: 700;
}

h3 {
  font-size: 28px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  font-weight: 700;
}

h5 {
  font-size: 20px;
  font-weight: 700;
}

h6 {
  font-size: 18px;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

ol,
ul {
  padding-left: 0 !important;
}

p {
  margin-top: 0;
  margin-bottom: 0 !important;
}

pre {
  white-space: pre-wrap; 
  word-wrap: break-word; 
  overflow-x: auto; 
  font-family: "Poppins", sans-serif !important;
}

.center {
  justify-content: center;
  display: flex;
  align-items: center;
}

.purple-button {
  background-color: var(--first-color);
  color: white;
}

.white-button {
  background-color: white;
  color: var(--first-color);
  border-color: var(--first-color);
  border-width: 2px;
}

.primary-red-button {
  background: var(--semantic-red-100, #f01b0d);
  color: white;
}

.secondary-purple-button {
  background: transparent;
  border-color: var(--first-color);
  border-width: 2px;
  color: var(--first-color);
}

.purple-button,
.white-button,
.primary-red-button,
.secondary-purple-button {
  font-size: 12px;
  width: 172px;
  height: 40px;
  border-radius: 9999px;
  letter-spacing: 0.96px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 370px) {
  .purple-button,
  .white-button {
    font-size: 11px;
    width: 150px;
    height: 35px;
  }
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--first-color) !important;
}

button:disabled,
button[disabled] {
  opacity: 0.5 !important;
}

/* -----------------------------------------------customed Scroll With Padding-------------------------------------*/
/* width */
.customedScroll ::-webkit-scrollbar {
  width: 37px;
  border-radius: 9999px;
}

/* Track */
.customedScroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 7px 10px 12px #d9d9d9;
  border-radius: 9999px;
  border: 15px solid white;
  margin-top: 110px;
  margin-bottom: 5px;
}

/* Handle */
.customedScroll ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 9999px;
  box-shadow: inset 10px 0 10px 10px var(--first-color);
  border: 15px solid white;
}

/* Handle on hover */
.customedScroll ::-webkit-scrollbar-thumb:hover {
  background: var(--first-color);
  border-right: 15px solid white;
  border-left: 15px solid white;
  border-radius: 9999px;
}

/* -----------------------------------------------customed Scroll No Padding-------------------------------------*/
.customedScrollNoPadding ::-webkit-scrollbar {
  width: 7px;
  border-radius: 9999px;
}

/* Track */
.customedScrollNoPadding ::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 7px 10px 12px #d9d9d9;
  border-radius: 9999px;
}

/* Handle */
.customedScrollNoPadding ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 9999px;
  box-shadow: inset 10px 0 10px 10px var(--first-color);
}

/* Handle on hover */
.customedScrollNoPadding ::-webkit-scrollbar-thumb:hover {
  background: var(--first-color);
  border-radius: 9999px;
}

/* no arrows for input number type*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* -----------------------------------------------Chatboot-------------------------------------*/
@media only screen and (max-width: 640px) {
  #do-widget-wrapper-do-open-button {
    display: fixed !important;
    bottom: 105px !important;
    right: 24px !important;
    width: 40px !important;
    height: 40px !important;
  }

  #do-widget-circle-close-bottom-right-wrapper {
    display: none !important;
  }
}

@media only screen and (min-width: 640px) and (max-width: 71024px) {
  #do-widget-wrapper-do-open-button,
  #do-widget-circle-close-bottom-right-wrapper {
    display: fixed !important;
    bottom: 110px !important;
    right: 40px !important;
    width: 40px !important;
    height: 40px !important;
  }

  #do-widget-wrapper {
    display: fixed !important;
    bottom: 160px !important;
    right: 40px !important;
  }
}

@media only screen and (min-width: 1024px) {
  #do-widget-wrapper-do-open-button,
  #do-widget-circle-close-bottom-right-wrapper {
    display: fixed !important;
    bottom: 35px !important;
    right: 25px !important;
    width: 40px !important;
    height: 40px !important;
  }

  #do-widget-wrapper {
    display: fixed !important;
    bottom: 85px !important;
    right: 25px !important;
  }
}

/* -----------------------------------------------calendar date range-------------------------------------*/
.rdp-day_selected {
  background-color: var(--first-color) !important;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: rgba(138, 85, 251, 0.1) !important;
}

/* -----------------------------------------------BigCalendar-------------------------------------*/
.rbc-header {
  border: none !important;
  font-weight: 600 !important;
}

.rbc-month-header {
  padding-bottom: 30px !important;
}

.rbc-date-cell {
  display: flex !important;
  flex-direction: column !important;
  text-align: center !important;
  align-items: center !important;
  margin-bottom: 22px !important;
  margin-top: 4px !important;
}

.rbc-day-bg + .rbc-day-bg {
  border: none !important;
}

.rbc-month-row + .rbc-month-row {
  border: none !important;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-date-cell.rbc-now {
  color: white !important;
  font-weight: 400 !important;
}

.rbc-button-link {
  padding: 10px !important;
  line-height: 14px !important;
}

.rbc-now button {
  border-radius: 4px !important;
  border: 1px solid var(--first-color) !important;
}

.rbc-calendar {
  border-width: 12px;
  border-color: #f8f6fc;
  background-color: #f8f6fc;
  font-family: "Poppins", sans-serif;
  padding: 20px;

  @media only screen and (max-width: 1024px) {
    padding: 3px;
  }
}

.rbc-month-row {
  background-color: #f8f6fc;
}

.rbc-month-view {
  border: none !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-event-continues-after {
  width: auto !important;
  margin-right: 0 !important;
}
.rbc-event-continues-prior {
  width: auto !important;
  margin-left: 0 !important;
}

.rbc-selected {
  border: 2px solid #8a55fb;
}

.rbc-event.rbc-selected {
  outline: none !important;
  /* Remove the outline */
  box-shadow: none !important;
  /* Remove the box-shadow */
}

/* Customize the button styles if needed */

.rbc-active {
  background-color: var(--first-color) !important;
  border-radius: 10000px !important;
  color: #f8f6fc !important;
  border-width: 0px !important;
}

/* Customize the header containing the month and date navigation */
.rbc-toolbar {
  border-radius: 15px;

  background-color: #f8f6fc;
}

.rbc-toolbar-label {
  font-size: 32px;

  background-color: #f8f6fc;
}

/* Customize the event container */
.rbc-event {
  border-radius: 15px;
  outline: none !important;
}

/* Customize the event tooltip */
.rbc-tooltip {
  color: white;
  border-radius: 15px;
}

/* Customize the event title */
.rbc-event-content {
  font-weight: bold;
}
/* Customize the date cell (day) */
.rbc-day-bg {
  border-radius: 15px;
}

/* Customize the selected date cell */
.rbc-selected {
  background-color: #6a1b9a;
  color: white;
}

@media only screen and (max-width: 768px) {
  .rbc-event {
    font-size: 12px;
  }

  .rbc-events-content {
    position: relative;
  }

  .rbc-event-content {
    overflow-y: auto;
    text-overflow: ellipsis;
  }

  .rbc-button-link {
    font-size: 12px;
  }

  .rbc-header {
    font-size: 12px;
  }
}

/* Customize Google maps */
.gm-style-iw, .gm-style-iw-d {
  padding: 0 !important;
  overflow: hidden !important;
  width: 350px !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .gm-style-iw, .gm-style-iw-d {
    width: 330px !important;
  }
}

.gm-style-iw-tc {
  display: none  !important;
}

.gm-ui-hover-effect {
  right: 0 !important;
  top: 0 !important;
}


.gm-ui-hover-effect span {
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}

/* Customize Chat Boot */
#do-avatar-button {
z-index: 40 !important;
}
